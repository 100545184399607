import React, { useState } from 'react';
import Home from '../Pages/Home/Home';
import Login from '../Pages/Login/Login';
import Logo from '../Images/Logo.svg';
import './style.css';
import { Transition } from "react-transition-group";
import headerLogo from '../Images/Frame.svg';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Space } from 'antd';

import Sidebar from "../Component/Sidebar";
import {
  CloseOutlined,
} from '@ant-design/icons';

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from './NavbarElements';
import { Layout, Menu, theme } from 'antd';
const { Header, Sider, Content } = Layout;


  
const Navbar = () => {

  const [isOpen, toggleIsOpen] = useState(false);
  const [navNumber, setNavNumber] = useState(1);
  const onOpenModalClick = () => toggleIsOpen(true);
  const onCloseModalClick = () => toggleIsOpen(false);
  const duration = 250;

  let navigate = useNavigate();

  const items = [
    {
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={()=>{setNavNumber(1)}}>
          Багууд
        </a>
      ),
      key: '0',
    },
    {
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={()=>{setNavNumber(2);
          
          navigate("/public/league");
          localStorage.setItem("pageNo", "2");
        console.log("first")}} >
          Тоглолтууд
        </a>
      ),
      key: '1',
    },
    {
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={()=>{navigate("/public/league"); localStorage.setItem("pageNo", "3");}} >
          Хүснэгт
        </a>
      ),
      key: '2',
    },
    {
      label: (
        <a target="_blank" rel="noopener noreferrer"  onClick={()=>{navigate("/public/league"); localStorage.setItem("pageNo", "4")}} >
          Статистик
        </a>
      ),
      key: '3',
    },
    ,
    {
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={()=>{navigate("/public/league"); localStorage.setItem("pageNo", "5")}} >
          Шүүгчид
        </a>
      ),
      key: '4',
    },
  ];

  return (
    <>
      <Nav>
        <Bars onClick={onOpenModalClick}/>
        <Transition in={isOpen} timeout={duration}>
        {state => (
            <Sidebar style={isOpen?{display:"block"}:{display:"none"}} duration={duration} state={state}>
              {isOpen && (
                <div style={{backgroundImage: `url(${headerLogo})`, width:"100%", backgroundPosition:"right", backgroundSize:"100% 100%"}}>
                  <h1 onClick={onCloseModalClick} style={{color:"#fff", float:"right", marginTop:"-20%", marginRight:"10%", zIndex:"20"}}>X</h1>
                  {/* <img src={headerLogo} style={{position:"absolute", right:"0", top:"5%"}}/> */}
                  <div style={{ marginLeft:"15%", marginTop:"30%", zIndex:"10"}}>
                    <p onClick={()=>{navigate("/public/league"); localStorage.setItem("pageNo", "1"); onCloseModalClick();}} style={{color:"#ffffff", fontSize:"16px", fontWeight:"500", cursor:"pointer"}}>Үндэсний Дээд Лиг</p> 
                    <div style={{marginLeft:"10%"}}>
                      <p onClick={()=>{navigate("/public/league"); localStorage.setItem("pageNo", "1"); onCloseModalClick();}} style={{color:"#ffffff", fontSize:"1.6vh", fontWeight:"500", cursor:"pointer"}}>Багууд</p> 
                      <p onClick={()=>{navigate("/public/league"); localStorage.setItem("pageNo", "2"); onCloseModalClick();}} style={{color:"#ffffff", fontSize:"1.6vh", fontWeight:"500", cursor:"pointer"}}>Тоглолтууд</p> 
                      <p onClick={()=>{navigate("/public/league"); localStorage.setItem("pageNo", "3"); onCloseModalClick();}} style={{color:"#ffffff", fontSize:"1.6vh", fontWeight:"500", cursor:"pointer"}}>Хүснэгт</p> 
                      <p onClick={()=>{navigate("/public/league"); localStorage.setItem("pageNo", "4"); onCloseModalClick();}} style={{color:"#ffffff", fontSize:"1.6vh", fontWeight:"500", cursor:"pointer"}}>Статистик</p> 
                      <p onClick={()=>{navigate("/public/league"); localStorage.setItem("pageNo", "5"); onCloseModalClick();}} style={{color:"#ffffff", fontSize:"1.6vh", fontWeight:"500", cursor:"pointer"}}>Шүүгчид</p> 
                    </div>                 
                    {/* <p style={{color:"#ffffff", fontSize:"16px", fontWeight:"500"}}>Тэмцээнүүд</p> */}
                    <p onClick={()=>{navigate("/public/news-list");onCloseModalClick();}} style={{color:"#ffffff", fontSize:"16px", fontWeight:"500"}}>Мэдээ</p>
                  </div>
                </div>

              )}
            </Sidebar>
        )}
      </Transition>

        <NavLink to='/' activeStyle>
        <img src={Logo}/>    
        </NavLink>
        <NavMenu>
          <NavLink onClick={()=>navigate("/public/league", {state:navNumber})} activeStyle>
          <Dropdown
            menu={{
              items,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Үндэсний Дээд Лиг
              </Space>
            </a>
          </Dropdown>
          </NavLink>
          {/* <NavLink to='/public/login' activeStyle>
            Тэмцээнүүд
          </NavLink> */}
          <NavLink to='/public/news-list' activeStyle>
            Мэдээ
          </NavLink>
        </NavMenu>
      </Nav>
    </>
  );
};
  
export default Navbar;