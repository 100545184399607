import React, {useState, useEffect} from 'react'
import {useLocation, useNavigate, Redirect } from 'react-router-dom';
import newsImg from '../../Images/newsImg.svg'
import db from '../../Firebase';
import { Row, Col } from 'antd';
import '../Home/styles.css'
import loaderIcon from '../../Images/loader.svg'

export default function NewsList() {
  const [info , setInfo] = useState([]);
  const [loaderCheck , setLoaderCheck] = useState(true);
  let navigate = useNavigate();
  // Start the fetch operation as soon as
  // the page loads
    useEffect(()=>{
      setLoaderCheck(true);
      fetchBlogs();
    },[])

  // Fetch the required data using the get() method
  const fetchBlogs=async()=>{
    const response=db.collection('/Basketball/news/doc1');
    const data=await response.get();
    const resDataNews = [];
    data.docs.forEach(item=>{
      if(item.data().type==="medee"){
        resDataNews.push(item.data())
      }
    const sda = resDataNews.slice(0).sort((a,b)=>
  a.date.localeCompare(b.date)).reverse();
     setInfo(sda);
     setLoaderCheck(false);
     console.log(sda)
    })

}
  return (
    <>
        <div style={loaderCheck?{width:"100%",position:"relative", height:"100vh", backgroundColor:"#0A163C", display:"flex"}:{display:"none"}}>
        <img src={loaderIcon} style={{display:"block", background:"transparent", width:"5%", animationDuration: "1s", animationName: "pulse", animationIterationCount: "infinite", color: "#ffffff", marginLeft:"auto", marginRight:"auto"}}/>
    </div>
  <div style={loaderCheck?{display:"none"}:{display:"block"}}>

      <Row gutter={[24, 0]} className="mobileLeague" style={{width:"90%", marginLeft:"auto", marginRight:"auto", paddingTop:"5%"}}>
      <div style={{display:"flex", marginLeft:"5%", marginBottom:"2%"}}>
            <p style={{fontSize:"1.5vh"}} onClick={()=>{navigate("/")}} className='timeline'>Нүүр хуудас {">"} </p>
            <p style={{fontSize:"1.5vh"}} onClick={()=>{navigate("/public/news-list")}} className='timeline'>Мэдээ</p>
            </div>
          {info.map((item, index)=>{
            if(index<1){
              return(
                <Col Col md={24} xs={24} xl={24}>
                  <div onClick={()=>navigate("/public/news", {state:item})} className='pointerMouse' style={{display:"block"}}>
                      <img src={item.image} style={{width:"100%", display:"block", marginLeft:"auto", marginRight:"auto"}}/>
                      <div style={{paddingTop:"5%", marginLeft:"5%", marginRight:"5%"}}>
                        <h3 className='truncate' style={{fontWeight:"bold", lineHeight:"1", width:"95%"}}>{item.title}</h3>
                        <p>{item.sub}</p>
                      </div>
                      
                  </div>
                </Col>
              )}
            })}
          {info.map((item, index)=>{
            if(index>=1){
              return(
                <Col Col md={6} xs={24} xl={6}>
                  <hr style={{background: "#dcdcdc", height:"1px", border:"none", width:"90%"}}></hr>
                  <div className='pointerMouse' onClick={()=>navigate("/public/news", {state:item})} style={{display:"flex"}}>
                      <img src={item.image} style={{width:"50%", padding:"5%"}}/>
                      <div className='truncate' style={{paddingTop:"5%", height:"50%"}}>
                        <h3 className='truncate' style={{fontWeight:"bold", lineHeight:"1"}}>{item.title}</h3>
                        <p className='truncate'>{item.sub}</p>
                      </div>
                      <p style={{textAlign:"right", position:"absolute", float:"right", right:"5%", bottom:"0", margin:"0", paddingBottom:"5%"}}>{item.date}</p>
                  </div>
                </Col>
          )}
        })}
      </Row>
      <Row gutter={[24, 0]} className="webLeague" style={{width:"90%", marginLeft:"auto", marginRight:"auto"}}>
      <div style={{display:"flex", marginLeft:"5%", marginBottom:"2%", marginTop:"5%"}}>
            <p onClick={()=>{navigate("/")}} className='timeline'>Нүүр хуудас {">"} </p>
            <p onClick={()=>{navigate("/public/news-list")}} className='timeline'>Мэдээ</p>
            </div>
          {info.map((item, index)=>{
            if(index<1){
              return(
                <Col Col md={24} xs={24} xl={24}>
                  <div onClick={()=>navigate("/public/news", {state:item})} className='pointerMouse' style={{height:"250px"}}>
                    <Row gutter={[24, 0]} className='news truncate'>
                    <Col md={8} xs={8} xl={8} style={{paddingLeft:"5%"}}>
                      <img style={item.hasImage?{display:"block", width:"100%", height:"95%"}:{display:"none"}} src={item.image}/>
                    </Col>
                    <Col md={14} xs={14} xl={14} className='truncate' style={{paddingTop:"2%"}}>
                    <div >
                        <h4 className='truncate' style={{fontWeight:"bold", lineHeight:"1"}}>{item.title}</h4>
                        <p className='truncate'>{item.sub}</p>
                    </div>                    
                    <p style={{textAlign:"left", margin:"0", paddingBottom:"5%"}}>{item.date}</p>
                    </Col>
                  </Row>
                  </div>
                </Col>
              )}
            })}
            <hr style={{background: "#dcdcdc", height:"1px", border:"none", width:"100%"}}></hr>
          {info.map((item, index)=>{
            if(index>=1){
              return(
                <Col Col md={8} xs={24} xl={8}>
                  <div onClick={()=>navigate("/public/news", {state:item})} className='pointerMouse' style={{display:"flex"}}>
                      <img src={item.image} style={{width:"50%", padding:"5%"}}/>
                      <div className='truncate' style={{paddingTop:"5%"}}>
                        <h3 className='truncate' style={{fontWeight:"bold", lineHeight:"1"}}>{item.title}</h3>
                        <p className='truncate'>{item.sub}</p>
                        <p style={{textAlign:"right", position:"absolute", float:"right", right:"0", bottom:"0", margin:"0", paddingBottom:"5%", paddingRight:"10%"}}>{item.date}</p>
                      </div>
                  </div>
                </Col>
          )}
        })}
      </Row>
      </div>
    </>
    
  )
}
