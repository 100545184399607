import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home/Home';
import Login from './Pages/Login/Login';
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Navbar from './Navbar';
import Footer from './Component/Footer';
import "antd/dist/antd.css";
import League from './Pages/Competitation/League/League';
import Games from './Pages/Competitation/League/Games';
import Team from './Pages/Competitation/League/Team';
import OtherComp from './Pages/Competitation/Other';
import db from './Firebase';
import {useState} from 'react';
import News from './Pages/News/News';
import NewsList from './Pages/News/NewsList';
import Tushaal from './Pages/News/Tushaal';

function App() {
  return (
    <div className='App'>
      
{/* <p>test test project</p> */}

    <BrowserRouter>
    <Navbar/>
      <Routes>
        <Route
            path="/"
            element={ <Navigate to="/public" /> }
        />

        <Route path="/public">
          <Route path="" element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="league" element={<League />} />
          <Route path="games" element={<Games />} />
          <Route path='team' element={<Team/>} />
          <Route path='other' element={<OtherComp/>}/>
          <Route path='news' element={<News/>}/>
          <Route path='news-list' element={<NewsList/>}/>
          <Route path='tushaal' element={<Tushaal/>}/>
          
        </Route>

      </Routes>
      <Footer/> 
    </BrowserRouter>   
    </div>

   
  );
}

export default App;
