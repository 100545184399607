import React, {useEffect, useState, useRef} from 'react'
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, message, Space, Tabs, Radio, DatePicker, Collapse, Select, Row, Col, Table } from 'antd';
import '../styles.css'
import imageSda from '../../../Images/newsImg.svg'
import locationIcon from '../../../Images/location.svg'
import { useNavigate, useLocation } from 'react-router-dom';
import player from "../../../Images/SinglePlayer.png";
import getCompetition from '../../../Services/getCompetition';
import seasonDetail from '../../../Services/seasonDetail';
import { getStanding } from '../../../Services/getStanding';
import getGames from '../../../Services/getGames';
import DatePick from '../../../Component/DatePick';
import Calendar from 'short-react-calendar';
import db from '../../../Firebase';
import { el } from 'date-fns/locale';
import SegmentedControl from '../../../Component/SegmentedControl';
import moment from 'moment';
import loaderIcon from '../../../Images/loader.svg'
// import 'react-calendar/dist/Calendar.css';

const columns = [
    {
      title: 'Баг',
      dataIndex: 'teamName',
    },
    {
      title: 'W',
      dataIndex: 'Win',
    },
    {
      title: 'L',
      dataIndex: 'Lost',
    },
  ];


const handleMenuClick = (e) => {
  message.info('Click on menu item.');
};

const items = [
  {
    label: '2021 - 2022',
    key: '1',
  },
  {
    label: '2020 - 2021',
    key: '2',
  },
  {
    label: '2019 - 2020',
    key: '3',
  },
];
const { Panel } = Collapse;

const menuProps = {
  items,
  onClick: handleMenuClick,
};
export default function League() {
    const [loaderCheck , setLoaderCheck] = useState(true);
    const [judge , setJudge] = useState([]);
    const [male , setMale] = useState([]);
    const [maleTeam , setMaleTeam] = useState(null);
    const [femaleTeam , setFemaleTeam] = useState(null);
    const [dateText, setDateText] = useState('');
    const [standingResult , setStandingResult] = useState([]);
    const [game , setGame] = useState([]);
    const [female , setFemale] = useState([]);
    const [value, setValue] = useState("Male");
    const [compId, setCompId] = useState("");
    const [teamId, setTeamId] = useState("");
    const [seasonId, setSeasonId] = useState("");
    const [dateValue, setDateValue] = useState(new Date());
    const [groupIdMale, setGroupIdMale] = useState("");
    const [groupIdFemale, setGroupIdFemale] = useState("");
    const [info , setInfo] = useState([]);
    const [pickedDate, setPickedDate] = useState(moment());
    const { state } = useLocation();

    const onChange = (valuee) => {


        console.log(valuee);
        if(value==="Male"){
            setMaleTeam(valuee);
            getSchedule({compId:compId, seasonId:seasonId, groupId:groupIdMale, teamId:valuee, date:dateText});
            getStand({compId:compId, seasonId:seasonId, groupId:groupIdMale});
            setTeamId(valuee);
        }else{
            setFemaleTeam(valuee);
            getSchedule({compId:compId, seasonId:seasonId, groupId:groupIdFemale, teamId:valuee, date:dateText});
            getStand({compId:compId, seasonId:seasonId, groupId:groupIdFemale});
            setTeamId(valuee);
        }
    };


    const onChangeRadio = (e) => {
        setValue(e.target.value);
      };

    const onChangeRadioFilter = (e) => {
    setValue(e.target.value);
    if(e.target.value==="Male"){
        getSchedule({compId:compId, seasonId:seasonId, groupId:groupIdMale, teamId:teamId, date:dateText});
        getStand({compId:compId, seasonId:seasonId, groupId:groupIdMale});
    }else{
        getSchedule({compId:compId, seasonId:seasonId, groupId:groupIdFemale, teamId:teamId, date:dateText});
        getStand({compId:compId, seasonId:seasonId, groupId:groupIdFemale});
    }
    };
    const onChangeRadioFilterWeb = (e) => {
        setValue(e);
        if(e==="Male"){
            getSchedule({compId:compId, seasonId:seasonId, groupId:groupIdMale, teamId:teamId, date:dateText});
            getStand({compId:compId, seasonId:seasonId, groupId:groupIdMale});
        }else{
            getSchedule({compId:compId, seasonId:seasonId, groupId:groupIdFemale, teamId:teamId, date:dateText});
            getStand({compId:compId, seasonId:seasonId, groupId:groupIdFemale});
        }
    };  
    // Fetch the required data using the get() method
    const fetchJudge=async()=>{
      const response=db.collection('/Basketball/Judge/Judge');
      const data=await response.get();
      const resDataJudge = [];
      data.docs.forEach(item=>{
        resDataJudge.push(item.data())
       
      })
    setJudge(resDataJudge);
      fetchCompetetion();
    }

    let navigate = useNavigate();
    
    useEffect(()=>{
        setLoaderCheck(true);
        var dateObj = new Date();
        setDateText(moment(dateObj).format("YYYY-MM-DD"))
        fetchBlogs();
      },[]);

    const fetchBlogs=async()=>{
    const response=db.collection('/Basketball/news/doc1');
    const data=await response.get();
    const resDataHighLight = [];
    const resDataNews = [];
    data.docs.forEach(item=>{
        if(item.data().hightlight===true){
        resDataHighLight.push(item.data())
        }
        if(item.data().type==="medee"){
        resDataNews.push(item.data())
        }
        const sda = resDataNews.slice(0).sort((a,b)=>
        a.date.localeCompare(b.date)).reverse();
        setInfo(sda);
    })
    fetchJudge();
}

    const getSchedule=(data)=>{
    getGames({compId:data.compId, seasonId:data.seasonId, groupId:data.groupId, team:data.teamId, date:data.date})
    .then((result1)=>{
        setGame(result1.data.data);
    })
    }
    const fetchCompetetion=()=>{
        getCompetition()
        .then((result) => {
            if(result.data.status==="success"){
                result.data.data.map((item1)=>{
                    item1.seasons.map((element)=>{
                        setCompId(item1._id);
                        setSeasonId(element._id);
                        getTeam({compId:item1._id, seasonId:element._id});
                        
                    })
                })
            }
        })
        .catch((err) => {
            console.log(err)
        });
    }
    const getStand =(data)=>{
        getStanding({compId:data.compId, seasonId:data.seasonId, groupId:data.groupId})
        .then((result)=>{
            if(result.data.status ==="success"){
                setStandingResult(result.data.data);
            }            
        })
    }
    const getTeam =(data) =>{
        seasonDetail(data)
        .then((result)=>{
            if(result.data.status ==="success"){
                result.data.data.map((card)=>{
                    if(card.groups.name==="Эрэгтэй"){
                        setMale(card.teams);
                        setGroupIdMale(card.groups._id);
                        var dateObj = new Date();
                        if(dateText===""){
                            getSchedule({compId:data.compId, seasonId:data.seasonId, groupId:card.groups._id, teamId:teamId, date:moment(dateObj).format("YYYY-MM-DD")});
                        }else{
                            getSchedule({compId:data.compId, seasonId:data.seasonId, groupId:card.groups._id, teamId:teamId, date:moment(dateObj).format("YYYY-MM-DD")});
                        }
                        getStand({compId:data.compId, seasonId:data.seasonId, groupId:card.groups._id})
                    }else{
                        setFemale(card.teams);
                        setGroupIdFemale(card.groups._id);
                    }
                    setLoaderCheck(false);
                })
            }
        })
    }

    const text = (
        <>
        <p>Баг</p>
        <div style={{display:"flex", marginBottom:"3%", borderRadius:"10px"}}>
            <Radio.Group onChange={onChangeRadioFilter} value={value} style={{display:"flex", width:"100%"}}>
                <Radio value={"Male"}>Эрэгтэй</Radio>
                <Radio style={{marginLeft:"15%"}} value={"Female"}>Эмэгтэй</Radio>
            </Radio.Group>
        </div>
        <div style={{display:"flex", alignItems:"center"}}>
        <Select
            showSearch
            placeholder="Баг сонгоно уу"
            optionFilterProp="children"
            style={value==="Male"?{borderRadius:"20px!important", width:"100%", fontWeight:"normal", display:"block"}:{display:"none"}}
            onChange={onChange}
            value={maleTeam}
            filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={male.map((province) => ({
                label: province.data.name,
                value: province._id,
              }))}
        />
        <p onClick={()=>{setTeamId(""); setMaleTeam(null); getSchedule({compId:compId, seasonId:seasonId, groupId:groupIdMale, teamId:"", date:dateText});}} style={value==="Male"?{paddingLeft:"2%", margin:"0px", color:"#d3d3d3", display:"block", cursor:"default"}:{display:"none"}}>X</p>
        </div>
        <div style={{display:"flex", alignItems:"center"}}>
        <Select
            showSearch
            placeholder="Баг сонгоно уу"
            optionFilterProp="children"
            style={value==="Female"?{borderRadius:"20px!important", width:"100%", fontWeight:"normal", display:"block"}:{display:"none"}}
            onChange={onChange}
            value={femaleTeam}
            // onSearch={onSearch}
            filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={female.map((province) => ({
                label: province.data.name,
                value: province._id,
              }))}
        />
        <p onClick={()=>{setTeamId(""); setFemaleTeam(null); getSchedule({compId:compId, seasonId:seasonId, groupId:groupIdFemale, teamId:"", date:dateText});}} style={value==="Female"?{paddingLeft:"2%", margin:"0px", color:"#d3d3d3", display:"block", cursor:"default"}:{display:"none"}}>X</p>
        </div>

                {/* <hr style={{background: "#0A163C", height:"0.5px", border:"none", marginTop:"5%", marginBottom:"5%"}}></hr> */}
                <h4 style={{fontWeight:"700", marginTop:"2%"}}>Тоглолтын төрөл</h4>
                <div style={{display:"flex", alignItems:"center"}}>
                <Select
                    showSearch
                    placeholder="Сонгоно уу"
                    optionFilterProp="children"
                    style={{borderRadius:"20px!important", width:"100%", fontWeight:"normal"}}
                    onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={[
                    {
                        value: 'season',
                        label: 'Улирлын',
                    },
                    ]}
                />     
                <p onClick={()=>{}} style={{paddingLeft:"2%", margin:"0px", color:"#d3d3d3", display:"block", cursor:"default"}}>X</p>                    
                </div>
        </>
      );

    const teamView =(
        <>
        <div style={value ==="Male"?{display:"block"}:{display:"none"}}>
            <Row gutter={[24, 0]} style={{marginLeft:"5%", marginRight:"5%"}}>
                {male.map((card)=>(
                    <Col md={6} xs={12} xl={6}>
                        <div onClick={()=>navigate("/public/team", {state:{compId:compId, seasonId:seasonId, groupId:groupIdMale, teamsId:card._id}})} style={{display:"block", borderRadius:"5px", backgroundColor:"#fff", margin:"2%", padding:"2%", height:"90%", cursor:"pointer"}}>
                            <img src={card.logoUrl} style={{paddingTop:"5%",borderRadius:"50%", width:"40%", marginLeft:"auto", marginRight:"auto", display:"block", height:"50%"}}/>
                            <p style={{textAlign:"center", marginTop:"10%", lineHeight:"1", fontWeight:"bold"}}>{card.data.name}</p>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
        <div style={value ==="Female"?{display:"block"}:{display:"none"}}>
        <Row gutter={[24, 0]} style={{marginLeft:"5%", marginRight:"5%"}}>
                {female.map((card)=>(
                    <Col md={6} xs={12} xl={6}>
                        <div onClick={()=>navigate("/public/team", {state:{compId:compId, seasonId:seasonId, groupId:groupIdFemale, teamsId:card._id}})} style={{display:"block", borderRadius:"5px", backgroundColor:"#fff", margin:"2%", padding:"2%", height:"90%", cursor:"pointer"}}>
                            <img src={card.logoUrl} style={{height:"50%", paddingTop:"5%",borderRadius:"50%", width:"40%", marginLeft:"auto", marginRight:"auto", display:"block"}}/>
                            <p style={{textAlign:"center", marginTop:"10%", lineHeight:"1", fontWeight:"bold"}}>{card.data.name}</p>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    </>
    )
    const stats =(
        <>

    </>
    )

    const gameSchedule=(

<>
            {
                game.length>0?<Row gutter={[24, 0]}>
                    {game.map((game1)=>(
                        <Col md={12} lg={12} xs={24}>
                        <div className='scheduleResponsive'>
                                <div style={{display:"flex", marginLeft:"2%", marginRight:"2%", justifyContent:"center", height:"180px"}}>
                                    <div style={game1.status===1?{position:"absolute", margin:"0px", width:"80%", marginTop:"2%", display:"flex", alignItems:"center"} : {display:"none"}}>
                                        <span style={{height: "5px",width: "5px", backgroundColor: "#CC1123", borderRadius: "50%", display: "inline-block",marginRight:"5px"}}></span>
                                        <p style={{fontWeight:"900", color:"#CC1123", fontSize:"10px", margin:"0px"}}>LIVE</p>
                                    </div>
                                    <div style={{display:"block", width:"35%", borderRadius:"5px", backgroundColor:"#fff", margin:"5% 2% 3% 2%"}}>
                                        {game1.team_a.map((image)=>(
                                        <img src={image.logoUrl} style={{paddingTop:"5%",borderRadius:"50%", width:"50%", marginLeft:"auto", marginRight:"auto", display:"block", marginTop:"10%", height:"50%"}}/>
                                        ))}
                                        <p style={{textAlign:"center", marginTop:"15%", lineHeight:"1", fontSize:"12px", fontWeight:"600"}}>{game1.data.team_a_name}</p>
                                    </div>
                                    <div style={{display:"block", marginTop:"10%"}}>
                                        <div style={game1.status===1?{display:"none"}:{width:"100%", display:"block", marginTop:"5%"}}>
                                            <div style={game1.data.arena_name===null?{display:"none"}:{display:"flex", justifyContent:"center", alignItems:"center", marginTop:"20%"}}>
                                                <img src={locationIcon}/>
                                                <p style={{margin:"0px", marginLeft:"5%", fontSize:"12px"}}>{game1.data.arena_name}</p>
                                            </div>
                                            <h2 style={{marginLeft:"auto", marginRight:"auto", display:"block", fontSize:"16px", textAlign:"center", margin:"0px"}}>{game1.data.time.substring(11,16)}</h2>
                                            <p style={{marginLeft:"auto", marginRight:"auto", display:"block", fontSize:"12px", textAlign:"center"}}>{game1.typeString}</p>
                                        </div>
                                        <div style={game1.status===2?{width:"100%", display:"flex", marginTop:"0%", justifyContent:"center", alignItems:"center"}:{display:"none"}}>
                                            <p style={parseInt(game1.data.score_b)<parseInt(game1.data.score_a)?{fontSize:"4vh", textAlign:"center", margin:"0px", fontWeight:"700", paddingRight:"5%"}:{fontSize:"4vh", textAlign:"center", margin:"0px", fontWeight:"400", paddingRight:"5%"}}>{game1.data.score_a}</p>
                                            <p style={{margin:"0px", fontSize:"3vh"}}>-</p>
                                            <p style={parseInt(game1.data.score_b)<parseInt(game1.data.score_a)?{fontSize:"4vh", margin:"0px",textAlign:"center", paddingLeft:"5%",fontWeight:"400"}:{fontSize:"4vh", margin:"0px",textAlign:"center", paddingLeft:"5%",fontWeight:"700"}}>{game1.data.score_b}</p>
                                        </div>
                                    </div>
                                    <div style={{display:"block", width:"35%", borderRadius:"5px", backgroundColor:"#fff", margin:"5% 2% 3% 2%"}}>
                                    {game1.team_b.map((image)=>(
                                        <img src={image.logoUrl} style={{paddingTop:"5%",borderRadius:"50%", width:"50%", marginLeft:"auto", marginRight:"auto", display:"block", marginTop:"10%", height:"50%"}}/>
                                        ))}
                                        <p style={{textAlign:"center", marginTop:"15%", lineHeight:"1", fontSize:"12px", fontWeight:"600"}}>{game1.data.team_b_name}</p>
                                    </div>   
                                </div>  
                                <div style={game1.status===1?{display:"flex"}:{display:"none"}}>
                                    <div onClick={()=>{window.location.href = game1.statsLink}} style={{width:"50%", borderTop:"1px solid #000", borderRight:"1px solid #000", display:"flex", justifyContent:"center", cursor:"pointer"}}>
                                    <p style={{textAlign:"center", marginTop:"8%", marginBottom:"8%", fontSize:"11px", fontWeight:"600"}}>ТОГЛОЛТЫН ЯВЦ</p>
                                    </div>
                                    <div onClick={()=>{window.location.href = game1.ticketLink}} style={{width:"50%", borderTop:"1px solid #000", borderRight:"1px solid #000", display:"flex", justifyContent:"center", cursor:"pointer"}}>
                                    <p style={{textAlign:"center", marginTop:"8%", marginBottom:"8%", fontSize:"11px", fontWeight:"600"}}>БИЛЕТ ЗАХИАЛГА</p>
                                    </div>
                                </div> 
                                <div onClick={()=>{window.location.href = game1.ticketLink}} style={game1.status===0?{display:"flex"}:{display:"none"}}>
                                    <div style={{width:"100%", borderTop:"1px solid #000", display:"flex", justifyContent:"center"}}>
                                    <p style={{textAlign:"center", marginTop:"4%", marginBottom:"4%", fontSize:"11px", fontWeight:"600", cursor:"pointer"}}>БИЛЕТ ЗАХИАЛГА</p>
                                </div>
                                </div>    
                                <div style={game1.status===2?{display:"flex"}:{display:"none"}}>
                                    <div onClick={()=>{window.location.href = game1.statsLink}} style={{width:"100%", borderTop:"1px solid #000", display:"flex", justifyContent:"center"}}>
                                    <p style={{textAlign:"center", marginTop:"4%", marginBottom:"4%", fontSize:"11px", fontWeight:"600", cursor:"pointer"}}>СТАТИСТИК</p>
                                </div>
                            </div>
                            
                        </div>
                        </Col>
                    ))} 
                </Row>:<div style={{width:"90%" ,backgroundColor:"#fff", marginLeft:"5%", borderRadius:"10px", height:"100px", display:"flex", alignItems:"center", justifyContent:"center", fontSize:"2vh", color:"#000", fontWeight:"bold"}}>Тоглолт олдсонгүй</div>
            }
        </>

    )
    const handlePickedDate =(e) =>{
     
        setPickedDate(e)
    } 
    const handleDatePicker = (e) =>{

        setPickedDate(e)
        setDateText(moment(e).format("YYYY-MM-DD"))
        getSchedule({compId:compId, seasonId:seasonId, groupId:groupIdMale, teamId:teamId, date:moment(e).format("YYYY-MM-DD")});

    }

  return (
    <>
    <div style={loaderCheck?{width:"100%",position:"relative", height:"100vh", backgroundColor:"#0A163C", display:"flex"}:{display:"none"}}>
        <img src={loaderIcon} style={{display:"block", background:"transparent", width:"5%", animationDuration: "1s", animationName: "pulse", animationIterationCount: "infinite", color: "#ffffff", marginLeft:"auto", marginRight:"auto"}}/>
    </div>
  <div style={loaderCheck?{display:"none"}:{display:"block"}}>
        <div style={{display:"flex", marginLeft:"5%", marginBottom:"2%"}}>
          <p style={{fontSize:"1.5vh"}} onClick={()=>{navigate("/")}} className='timeline'>Нүүр хуудас {">"} </p>
          <p style={{fontSize:"1.5vh"}} onClick={()=>{navigate("/public/league"); localStorage.setItem("pageNo", "1");}} className='timeline'>Дээд лиг </p>
        </div>
        <div style={{ marginLeft:"5%",display:"flex"}}>
            <p style={{color:"#0A163C", fontWeight:"bold", fontSize:"20px"}}>ҮНДЭСНИЙ ДЭЭД ЛИГ</p>
            {/* <RangePicker picker="year" style={{width:"80%"}}/> */}
            {/* <Space style={{marginLeft:"auto", marginRight:"5%"}} wrap>
                <Dropdown menu={menuProps}>
                <Button style={{borderRadius:"5px"}}>
                    <Space style={{color:"#000", fontWeight:"bold"}}>
                    2022 - 2023
                    <DownOutlined />
                    </Space>
                </Button>
                </Dropdown>
            </Space> */}
        </div>
        <div className='mobileLeague' style={{backgroundColor:"#112259"}}>
            <Tabs className='leagueTab' defaultActiveKey={String(localStorage.getItem("pageNo"))}>
                <Tabs.TabPane tab="Багууд" key="1">
                    <div style={{display:"flex", marginBottom:"3%", backgroundColor:"#737d9d", padding:"5%", borderRadius:"10px", marginRight:"5%", marginLeft:"5%"}}>
                        <Radio.Group onChange={onChangeRadio} value={value} style={{display:"flex", width:"100%", justifyContent:"center"}}>
                            <Radio style={{marginRight:"auto", marginLeft:"15%", color:"#fff"}} value={"Male"}>Эрэгтэй</Radio>
                            <Radio style={{marginRight:"15%", marginLeft:"auto", color:"#fff"}} value={"Female"}>Эмэгтэй</Radio>
                        </Radio.Group>
                    </div>
                    {teamView}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Тоглолтууд" key="2">
                    <div>
                        <div style={{backgroundColor:"#fff", borderRadius:"10px", marginLeft:"5%", marginRight:"5%", marginTop:"5%", marginBottom:"5%"}}>
                        <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginRight:"auto", paddingBottom:"2%" ,paddingTop:"5%"}}>
                                <h3 style={{margin:"0%", fontWeight:"700"}}>{moment(pickedDate).year()} оны {moment(pickedDate).month()+1} сар</h3>
                                <DatePicker defaultValue={pickedDate}
                                value = {moment(pickedDate)}
                                placeholder=''
                                onChange={(date, dateString )=>{
                                        setDateText(dateString);
                                        setPickedDate(dateString)
                                        getSchedule({compId:compId, seasonId:seasonId, groupId:groupIdFemale, teamId:teamId, date:dateString});
                                }}

                                bordered={false} allowClear = {false} />
                            </div>
                            <div style={{margin:"0px", width:"100%", position:"relative"}}>
                                <DatePick
                                handlePickedDate = {handlePickedDate}
                                handleDatePicker = {handleDatePicker}
                                pickedDate ={pickedDate} />
                            </div>

                            <div>
                                <Collapse
                                    expandIconPosition={"end"}
                                    bordered={false}
                                    style={{backgroundColor:"#fff", borderRadius:"10px"}}
                                >
                                    <Panel header="Шүүлт" key="1" style={{fontWeight:"700"}}>
                                        {text}
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                    {gameSchedule}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Хүснэгт" key="3">
                    <div style={{display:"flex", marginBottom:"3%", backgroundColor:"#737d9d", padding:"5%", borderRadius:"10px", marginRight:"5%", marginLeft:"5%"}}>
                        <Radio.Group onChange={onChangeRadioFilter} value={value} style={{display:"flex", width:"100%", justifyContent:"center"}}>
                        <Radio style={{marginRight:"auto", marginLeft:"15%", color:"#fff"}} value={"Male"}>Эрэгтэй</Radio>
                        <Radio style={{marginRight:"15%", marginLeft:"auto", color:"#fff"}} value={"Female"}>Эмэгтэй</Radio>
                        </Radio.Group>
                    </div>
                    <div style={{ marginBottom:"3%", borderRadius:"10px", marginRight:"5%", marginLeft:"5%"}}>
                        <h3 style={{marginLeft:"10%", color:"#fff", fontWeight:"600"}}>Standing</h3>
                        <Table columns={columns} dataSource={standingResult.map((province, index) => 
                            ({
                            key: index,
                            teamName: province.name,
                            Win: province.result.win,
                            Lost: province.result.loss,
                        }))} size="small" />
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Статистик" key="4">
                    <div style={{display:"flex", marginBottom:"3%", backgroundColor:"#737d9d", padding:"5%", borderRadius:"10px", marginRight:"5%", marginLeft:"5%"}}>
                        <Radio.Group style={{display:"flex", width:"100%", justifyContent:"center"}}>
                            <Radio style={{marginRight:"auto", marginLeft:"15%", color:"#fff"}} value={"male"}>Эрэгтэй</Radio>
                            <Radio style={{marginRight:"15%", marginLeft:"auto", color:"#fff"}} value={"female"}>Эмэгтэй</Radio>
                        </Radio.Group>
                    </div>
                        {stats}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Шүүгчид" key="5">
                    <Row gutter={[24, 0]} style={{marginBottom:"10%"}}>
                        {judge.map((item3)=>(
                        <Col md={6} xs={12} xl={6} style={{marginBottom:"5%", justifyContent:"center"}}>
                            <div style={{backgroundImage: `linear-gradient(transparent, #070F28 150%),url(${item3.image})`, marginLeft:"auto", marginRight:"auto", backgroundPosition: "center", backgroundRepeat:"no-repeat", backgroundSize:"cover", height:"100%", borderRadius:"15px", width:"90%"}}>
                                <p style={{color:"#fff", textAlign:"center", paddingTop:"110%", fontWeight:"600", fontSize:"12px"}}>{item3.Name}</p>
                                <div style={{display:"flex", width:"100%", lineHeight:"1"}}>
                                    <div style={{width:"50%"}}>
                                        <h3 style={{textAlign:"center",fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>{item3.Country}</h3>
                                        <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>улс</p>
                                    </div>
                                    <div style={{width:"50%"}}>
                                        <h3 style={{textAlign:"center", fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>{item3.Rank}</h3>
                                        <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>зэрэглэл</p>
                                    </div>
                                </div>
                            </div>

                        </Col>
                        ))}
                    </Row>
                </Tabs.TabPane>
            </Tabs>
        </div>
        <div className='webLeague' style={{backgroundColor:"#112259"}}>
        <Row gutter={[24, 0]} >
        <Col md={16} xl={16}>
        <Tabs className='leagueTab' defaultActiveKey={String(localStorage.getItem("pageNo"))}>
                <Tabs.TabPane tab="Багууд" key="1">
                    <div>
                    <SegmentedControl
                        name="group-1"
                        callback={(val) => setValue(val)}
                        controlRef={useRef()}
                        segments={[
                        {
                            label: "Эрэгтэй",
                            value: "Male",
                            ref: useRef()
                        },
                        {
                            label: "Эмэгтэй",
                            value: "Female",
                            ref: useRef()
                        },
                        ]}
                    />
                    </div>
                    {teamView}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Тоглолтууд" key="2">
                    <div>
                        <div style={{backgroundColor:"#fff", borderRadius:"10px", paddingTop:"5%"}}>
                            <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginRight:"auto", paddingBottom:"2%"}}>
                                <h3 style={{margin:"0%", fontWeight:"700"}}>{moment(pickedDate).year()} оны {moment(pickedDate).month()+1} сар</h3>
                                <DatePicker defaultValue={pickedDate}
                                value = {moment(pickedDate)}
                                placeholder=''
                                onChange={(date, dateString )=>{
                                        setDateText(dateString);
                                        setPickedDate(dateString)
                                        getSchedule({compId:compId, seasonId:seasonId, groupId:groupIdFemale, teamId:teamId, date:dateString});
                                }}

                                bordered={false} allowClear = {false} />
                            </div>
                            <div style={{margin:"0px", width:"100%", position:"relative", marginBottom:"2%"}}>
                                <DatePick
                                handlePickedDate = {handlePickedDate}
                                handleDatePicker = {handleDatePicker}
                                pickedDate ={pickedDate} />
                            </div>
                            <div>
                                <Collapse
                                    expandIconPosition={"end"}
                                    bordered={false}
                                    style={{backgroundColor:"#fff", borderRadius:"10px"}}
                                >
                                    <Panel header="Шүүлт" key="1" style={{fontWeight:"700"}}>
                                        {text}
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop:"5%", marginLeft:"-3%", marginRight:"3%"}}>
                    {gameSchedule}
                    </div>

                </Tabs.TabPane>
                <Tabs.TabPane tab="Хүснэгт" key="3">
                <SegmentedControl
                        name="group-1"
                        callback={onChangeRadioFilterWeb}
                        controlRef={useRef()}
                        segments={[
                        {
                            label: "Эрэгтэй",
                            value: "Male",
                            ref: useRef()
                        },
                        {
                            label: "Эмэгтэй",
                            value: "Female",
                            ref: useRef()
                        },
                        ]}
                    />
                    <div style={{ marginBottom:"3%", borderRadius:"10px", marginRight:"5%", marginLeft:"5%"}}>
                        <h3 style={{marginLeft:"10%", color:"#fff", fontWeight:"600"}}>Standing</h3>
                        <Table columns={columns} dataSource={standingResult.map((province, index) => 
                            ({
                            key: index,
                            teamName: province.name,
                            Win: province.result.win,
                            Lost: province.result.loss,
                        }))} size="small" />
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Статистик" key="4">
                <SegmentedControl
                        name="group-1"
                        callback={(val) => setValue(val)}
                        controlRef={useRef()}
                        segments={[
                        {
                            label: "Эрэгтэй",
                            value: "Male",
                            ref: useRef()
                        },
                        {
                            label: "Эмэгтэй",
                            value: "Female",
                            ref: useRef()
                        },
                        ]}
                    />
                        {stats}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Шүүгчид" key="5">
                    <Row gutter={[24, 0]} style={{marginBottom:"10%"}}>
                        {judge.map((item3)=>(
                        <Col md={6} xs={12} xl={6} style={{marginBottom:"5%", justifyContent:"center"}}>
                            <div style={{backgroundImage: `linear-gradient(transparent, #070F28 150%),url(${item3.image})`, marginLeft:"auto", marginRight:"auto", backgroundPosition: "center", backgroundRepeat:"no-repeat", backgroundSize:"cover", height:"100%", borderRadius:"15px", width:"90%"}}>
                                <p style={{color:"#fff", textAlign:"center", paddingTop:"110%", fontWeight:"600", fontSize:"12px"}}>{item3.Name}</p>
                                <div style={{display:"flex", width:"100%", lineHeight:"1"}}>
                                    <div style={{width:"50%"}}>
                                        <h3 style={{textAlign:"center",fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>{item3.Country}</h3>
                                        <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>улс</p>
                                    </div>
                                    <div style={{width:"50%"}}>
                                        <h3 style={{textAlign:"center", fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>{item3.Rank}</h3>
                                        <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>зэрэглэл</p>
                                    </div>
                                </div>
                            </div>

                        </Col>
                        ))}
                    </Row>
                </Tabs.TabPane>
            </Tabs>
        </Col>
        <Col md={8} xl={8}>
        <div style={{backgroundColor:"#fff", marginTop:"3%", borderRadius:"20px", width:"100%"}}>
            <h2  style={{fontWeight:"bold", marginLeft:"5%", paddingBottom:"3%", paddingTop:"3%"}}>MBA эргэн тойронд</h2>
                {info.map((item, index)=>{
                    if(index<5){
                        return(
                            <div style={{width:"100%", marginLeft:"auto", marginRight:"auto", position:"relative"}}>
                                <hr style={{background: "#dcdcdc", height:"1px", border:"none", marginLeft:"1%", marginRight:"1%", width:"90%", position:"relative"}}></hr>
                                <Row gutter={[24, 0]} className='truncate' onClick={()=>navigate("/public/news",{state:item})}>
                                    <Col md={9} xs={9} xl={9} style={{paddingLeft:"5%"}}>
                                        <img className='newsImage' style={item.hasImage?{display:"block", width:"100%", height:"100%"}:{display:"none"}} src={item.image}/>
                                    </Col>
                                    <Col md={15} xs={15} xl={15} className='truncate' style={{paddingTop:"2%"}}>
                                    
                                        <div className='truncate' style={{paddingTop:"5%", width:"85%"}}>
                                        <h3 className='truncate' style={{fontWeight:"bold", lineHeight:"1", overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>{item.title}</h3>
                                        <p className='truncate'>{item.sub}</p>
                                        </div>
                                        <p style={{textAlign:"right", paddingBottom:"5%", paddingRight:"15%"}}>{item.date}</p>
                                    </Col>
                                </Row>
                
                            </div>
                        
                        )
                    }
                    })}
                    <p onClick={()=>navigate("/public/news-list")} style={info.length>5?{display:"block", marginLeft:"auto", width:"20%", marginRight:"3%", cursor:"pointer"}:{display:"none"}}>see more</p>
            </div>
            </Col>
        </Row>


        </div>
    </div>
    </>
  )
}
