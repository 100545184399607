import React, { useState, useRef, useEffect} from 'react';
import player from "../../../Images/test.png";
import {useLocation, useNavigate, Redirect } from 'react-router-dom';
import teamDetail from '../../../Services/teamDetail';
import { Row, Col } from 'antd';
import '../styles.css'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import moment from 'moment';

import { Pagination } from "swiper";


export default function Team() {

    const { state } = useLocation();
    const [players , setPlayers] = useState([]);
    const [teamName , setTeamName] = useState("");

    let navigate = useNavigate();
    var b = "FG%";

    useEffect(() => {
        teamDetail(state)
        .then((result)=>{
            if(result.data.status ==="success"){
                setTeamName(result.data.data.data.name);
                setPlayers(result.data.data.players);
            }

        })
        .catch((err)=>{
            console.log(err)
        })
      }, []);
     
      const view =(
        <>
        <Row gutter={[24, 0]} style={{marginBottom:"10%", marginLeft:"2%", marginRight:"2%"}}>
            {players.map((item)=>{
                var years = moment().diff(moment(item.data.birth_date, "YYYY-MM-DD"), 'years');
                return(
                    
                        <Col className='sdaCol' md={4} xs={12} xl={4} style={{position:"relative"}}>
                            {/* <div  style={{width:"100%", position:"absolute", paddingTop:"20%", zIndex:"0"}}>
                                    <img src={item.imageUrl} style={{backgroundImage: `linear-gradient(#ffffff, #070F28)`,width:"100%", backgroundPosition: "center", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}/>
                                </div>
                                <div style={{display:"block", zIndex:"1", paddingTop:"30%"}}>
                               
                                </div> */}
                            <div style={{backgroundImage: `linear-gradient(transparent, #070F28 150%),url(${item.imageUrl})`,  backgroundPosition: "center", backgroundRepeat:"no-repeat", backgroundSize:"cover", height:"250px", borderRadius:"15px", width:"100%", position:"relative"}}>
                            </div>  
                            <div style={{zIndex:"0", position:"relative", opacity:"1", marginTop:"-50%"}}>
                                <p style={{color:"#fff", textAlign:"center", fontWeight:"600", fontSize:"12px", position:"relative"}}>{item.data.first_name}</p>
                                <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
                                    <SwiperSlide>
                                        <div style={{display:"flex", width:"100%", lineHeight:"1"}}>
                                            <div style={{width:"50%"}}>
                                                <h3 style={{textAlign:"center",fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>{item.data.shirt_number}</h3>
                                                <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>Хувийн дугаар</p>
                                            </div>
                                            <div style={{width:"50%"}}>
                                                <h3 style={{textAlign:"center", fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>{item.data.birth_date===null?"0":years}</h3>
                                                <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>Нас</p>
                                            </div>
                                        </div>
                                        <div style={{display:"flex", width:"100%", lineHeight:"1"}}>
                                            <div style={{width:"50%"}}>
                                                <h3 style={{textAlign:"center",fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>{item.data.height} см</h3>
                                                <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>Өндөр</p>
                                            </div>
                                            <div style={{width:"50%"}}>
                                                <h3 style={{textAlign:"center", fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>{item.data.weight} кг</h3>
                                                <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>Жин</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div style={{display:"flex", width:"100%", lineHeight:"1"}}>
                                            <div style={{width:"50%"}}>
                                                <h3 style={{textAlign:"center",fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>{item.stats.MINS}</h3>
                                                <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>MINS</p>
                                            </div>
                                            <div style={{width:"50%"}}>
                                                <h3 style={{textAlign:"center", fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>{item.stats.PTS}</h3>
                                                <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>PTS</p>
                                            </div>
                                        </div>
                                        <div style={{display:"flex", width:"100%", lineHeight:"1"}}>
                                            <div style={{width:"50%"}}>
                                                <h3 style={{textAlign:"center",fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>{item.stats['FG%']}</h3>
                                                <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>FG%</p>
                                            </div>
                                            <div style={{width:"50%"}}>
                                                <h3 style={{textAlign:"center", fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>{item.stats.REB}</h3>
                                                <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>REB</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div style={{display:"flex", width:"100%", lineHeight:"1"}}>
                                            <div style={{width:"50%"}}>
                                                <h3 style={{textAlign:"center",fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>{item.stats['3P%']}</h3>
                                                <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>3P</p>
                                            </div>
                                            <div style={{width:"50%"}}>
                                                <h3 style={{textAlign:"center", fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>{item.stats['3P%']}</h3>
                                                <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>3P%</p>
                                            </div>
                                        </div>
                                        <div style={{display:"flex", width:"100%", lineHeight:"1"}}>
                                            <div style={{width:"50%"}}>
                                                <h3 style={{textAlign:"center",fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>{item.stats.AST}</h3>
                                                <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>AST</p>
                                            </div>
                                            <div style={{width:"50%"}}>
                                                <h3 style={{textAlign:"center", fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>{item.stats.BLK}</h3>
                                                <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>BLK</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
        
                                </div>
                        </Col>
                    
                )
            })}
        </Row>
        </>
      )

  return (
    <div>
        <br></br>
        <div className='teamNameWeb'>
            <div style={{display:"flex", marginLeft:"5%", marginBottom:"2%"}}>
            <p onClick={()=>{navigate("/")}} className='timeline'>Нүүр хуудас {">"} </p>
            <p onClick={()=>{navigate("/public/league"); localStorage.setItem("pageNo", "1");}} className='timeline'>Дээд лиг {">"} </p>
            <p onClick={()=>{navigate("/public/team")}} className='timeline'>Багууд</p>
            </div>
            <h3 style={{fontWeight:"bold", marginLeft:"5%", fontSize:"17px"}}>{teamName}</h3>
        </div>
        <div className='teamNameMobile'>
        <div style={{display:"flex", marginLeft:"5%", marginBottom:"2%"}}>
            <p style={{fontSize:"1.5vh"}} onClick={()=>{navigate("/")}} className='timeline'>Нүүр хуудас {">"} </p>
            <p style={{fontSize:"1.5vh"}} onClick={()=>{navigate("/public/league"); localStorage.setItem("pageNo", "1");}} className='timeline'>Дээд лиг {">"} </p>
            <p style={{fontSize:"1.5vh"}} onClick={()=>{navigate("/public/team")}} className='timeline'>Багууд</p>
            </div>
            <h3 style={{fontWeight:"bold", marginLeft:"5%", fontSize:"17px"}}>{teamName}</h3>
        </div>
        <div className='playersWeb'>
            {view}
        </div>
    </div>
  )
}
