import React, {useState, useEffect} from 'react'
import { Input, Button, Row, Col, Carousel } from 'antd';
import Competitation from '../../Component/Competitation';
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "./slider-animation.css";
import "./styles.css"
import newsImg from '../../Images/newsImg.svg'
import db from '../../Firebase';
import { useNavigate } from 'react-router-dom';
import HorizontalScroll from 'react-horizontal-scrolling'
import locationIcon from '../../Images/location-icon.svg'
import clockIcon from '../../Images/ClockCircleOutlined.svg'
import { el } from 'date-fns/locale';
import getGames from '../../Services/getGames';
import getCompetition from '../../Services/getCompetition';
import { getStanding } from '../../Services/getStanding';
import seasonDetail from '../../Services/seasonDetail';
import loaderIcon from '../../Images/loader.svg'
import moment from 'moment';



export default function Home() {
  const [info , setInfo] = useState([]);
  const [loaderCheck , setLoaderCheck] = useState(true);
  const [hightlightArray , setHightlightArray] = useState([]);

  const [male , setMale] = useState([]);
  const [maleName , setMaleName] = useState([]);
  const [femaleName , setFemaleName] = useState([]);
  const [standingResult , setStandingResult] = useState([]);
  const [game , setGame] = useState([]);
  const [female , setFemale] = useState([]);
  const [value, setValue] = useState("Male");
  const [compId, setCompId] = useState("");
  const [seasonId, setSeasonId] = useState("");
  const [teamId, setTeamId] = useState("");
  const [groupIdMale, setGroupIdMale] = useState("");
  const [groupIdFemale, setGroupIdFemale] = useState("");

  let navigate = useNavigate();
  // Start the fetch operation as soon as
  // the page loads
    useEffect(()=>{
      console.log("first")
      setLoaderCheck(true);
      fetchBlogs();
    },[])
    
  const getSchedule=(data)=>{
      const haha = [];
      var dateObj = new Date();
      console.log(moment(dateObj).format("YYYY-MM-DD"))
      // for (var d = new Date(2012, 0, 1); d <= now; d.setDate(d.getDate() + 1)) {
      // }
      for (var i = 0; i<30; i++) {
      getGames({compId:data.compId, seasonId:data.seasonId, groupId:data.groupId, team:data.teamId, date:moment(dateObj).add(i, 'days').format('YYYY-MM-DD')})
        .then((result1)=>{
            // setGame(result1.data.data);
            // haha = [haha=>[haha, result1.data.data]];
            setGame(game => [...game, ...result1.data.data]);
        })
        // console.log(game, "sda");
      }
  }
  const fetchCompetetion=()=>{
      getCompetition()
      .then((result) => {
          if(result.data.status==="success"){
              result.data.data.map((item1)=>{
                  item1.seasons.map((element)=>{
                      setCompId(item1._id);
                      setSeasonId(element._id);
                      getTeam({compId:item1._id, seasonId:element._id});
                      
                  })
              })
          }
      })
      .catch((err) => {
          console.log(err)
      });
  }

  const getTeam =(data) =>{
      seasonDetail(data)
      .then((result)=>{
          if(result.data.status ==="success"){
              result.data.data.map((card)=>{
                  if(card.groups.name==="Эрэгтэй"){
                      setGroupIdMale(card.groups._id);
                      getSchedule({compId:data.compId, seasonId:data.seasonId, groupId:card.groups._id, teamId:""});
                  }else{
                      setFemale(card.teams);
                      setGroupIdFemale(card.groups._id);
                      getSchedule({compId:data.compId, seasonId:data.seasonId, groupId:card.groups._id, teamId:""});
                  }
                  setLoaderCheck(false);
              })
          }
      })
  }

  // Fetch the required data using the get() method
  const fetchBlogs=async()=>{
    const response=db.collection('/Basketball/news/doc1');
    const data=await response.get();
    const resDataHighLight = [];
    const resDataNews = [];
    data.docs.forEach(item=>{
      if(item.data().hightlight===true){
        resDataHighLight.push(item.data())
      }
      if(item.data().type==="medee"){
        resDataNews.push(item.data())
      }
    const sda = resDataNews.slice(0).sort((a,b)=>
  a.date.localeCompare(b.date)).reverse();
     setInfo(sda);
     console.log(sda)
     setHightlightArray(resDataHighLight);
    })
    fetchCompetetion();
}
   return (
    <>
      <div style={loaderCheck?{width:"100%",position:"relative", height:"100vh", backgroundColor:"#0A163C", display:"flex"}:{display:"none"}}>
        <img src={loaderIcon} style={{display:"block", background:"transparent", width:"5%", animationDuration: "1s", animationName: "pulse", animationIterationCount: "infinite", color: "#ffffff", marginLeft:"auto", marginRight:"auto"}}/>
      </div>
      <div style={loaderCheck?{display:"none"}:{display:"block"}}>
      {/* <Competitation/> */}
      <div className="scrollviewDiv" >
      {game.map((game1)=>(
        <div style={{borderRight:"1px solid #000"}}>
            <div style={{display:"flex", width:"300px"}}>
              <div style={{display:"block"}}>
                <p style={{fontWeight:"600", margin:"10px", fontSize:"12px"}}>{game1.data.team_a_name}</p>
                <p style={{fontWeight:"600", margin:"10px", fontSize:"12px"}}>{game1.data.team_b_name}</p>
              </div>
              <div style={{display:"block", marginLeft:"auto"}}>
                <div style={{display:"flex", alignItems:"center", margin:"10px"}}>
                  <img src={clockIcon} style={{marginRight:"10px"}}/>
                  <div style={{display:"block"}}>
                  <p style={{margin:"0px", textAlign:"center"}}>{game1.data.time.substring(0,11)}</p>
                  <p style={{margin:"0px", textAlign:"center"}}>{game1.data.time.substring(11,16)}</p>
                  </div>

                </div>
                {/* <div style={{display:"flex", justifyContent:"center", alignItems:"center", margin:"10px"}}>
                  <img src={locationIcon} style={{marginRight:"10px"}}/>
                  <p style={{margin:"0px"}}>{game1.data.arena_name===null?'UG Arena':game1.data.arena_name}</p>
                </div> */}
              </div>
            </div>
          </div>
          ))} 
      </div>
      <div>
      <Carousel className='webCarousel' style={{width:"100%", position:"relative"}} autoplay autoplaySpeed={2000}>
      {hightlightArray.map((item, index) => (
          <div>
            <div
              style={{cursor:"pointer", position:"relative"}}
              onClick={()=>navigate("/public/news", {state:item})}
            >
              <img src={item.image} style={{width:"100%", backgroundPositionX:"center", position:"absolute"}}/>
              <br></br>
              <div className='hilghLightDiv' style={{position:"relative", backgroundColor:"rgba(7, 15, 40, 0.5 )", paddingBottom:"10%", paddingLeft:"5%"}}>
                <h1 className='titleCarousel' style={{ color:"#fff", borderRadius:"10px", whiteSpace:"norwap", display:"inline-block", width:"auto"}}>{item.title}</h1>
                <button style={{cursor:"pointer!important", display:"block", backgroundColor:"#CC1123", color:"#fff", zIndex:"10", position:"relative"}}>Дэлгэрэнгүй</button>
              </div>
            </div>
          </div>
          ))}
      </Carousel>
      <br></br>
      </div>
      <div className='mobileRes'>
      <h2 style={{fontWeight:"bold", marginLeft:"5%", paddingBottom:"3%", paddingTop:"3%"}}>MBA эргэн тойронд</h2>
        {info.map((item, index)=>{
          if(index<5){
            return(
                <div className='newsElement' style={{display:"block", position:"relative"}}>
                <hr style={{background: "#dcdcdc", height:"1px", border:"none", marginLeft:"5%", marginRight:"5%", width:"90%", position:"relative"}}></hr>
                <Row gutter={[24, 0]} className='news truncate' onClick={()=>navigate("/public/news",{state:item})}>
                  <Col md={12} xs={9} xl={12} style={{paddingLeft:"5%"}}>
                    <img style={item.hasImage?{display:"block", width:"100%"}:{display:"none"}} src={item.image} className='newsImage'/>
                  </Col>
                  <Col md={12} xs={15} xl={12} className='truncate' style={{paddingTop:"5%"}}>
                  <div >
                      <h4 className='truncate' style={{fontWeight:"bold", lineHeight:"1"}}>{item.title}</h4>
                      <p className='truncate'>{item.sub}</p>

                  <p style={{textAlign:"right", position:"absolute", float:"right", right:"0", bottom:"0", margin:"0", paddingBottom:"5%"}}>{item.date}</p>
                  </div>                    
                  </Col>
                </Row>
                </div>
              
            )
          }
        })}
        <br></br>

        <p onClick={()=>navigate("/public/news-list")} style={info.length>5?{display:"block", marginLeft:"auto", width:"20%", marginRight:"3%", cursor:"pointer"}:{display:"none"}}>see more</p>
        
         <br></br>

      </div>
      <div className='webRes'>
      <h2 style={{fontWeight:"bold", marginLeft:"5%", paddingBottom:"3%", paddingTop:"3%"}}>MBA эргэн тойронд</h2>

      <Row gutter={[24, 0]} style={{marginBottom:"10%"}}>
        <Col Col md={12} xs={12} xl={12}>
                  {info.map((item, index)=>{
                    if(index<1){
                      return(
                          <div style={{backgroundImage: `url(${item.image})`, backgroundPosition: "center", backgroundRepeat:"no-repeat", backgroundSize:"cover", height:"600px", borderRadius:"10px", width:"100%", padding:"5%", marginLeft:"5%", cursor:"pointer"}}>
                            <div className='news' style={{display:"flex"}} onClick={()=>navigate("/public/news",{state:item})}>
                                {/* <img style={item.hasImage?{display:"block", width:"30%"}:{display:"none"}} src={item.image} className='newsImage'/> */}
                                <div style={{paddingTop:"2%", marginLeft:"5%", marginTop:"70%"}}>
                                  <h2 style={{color:"#fff" ,fontWeight:"bold", lineHeight:"1"}}>{item.title}</h2>
                                </div>
                            </div>
                          </div>
                        
                      )
                    }
                  })}
        </Col>
        <Col Col md={11} xs={11} xl={11} style={{marginLeft:"3%"}}>
        {info.map((item, index)=>{
          if(index<5){
            return(
                <div style={{height:"150px"}}>
                  <Row gutter={[24, 0]} className='news truncate' style={{cursor:"pointer", marginBottom:"5%"}} onClick={()=>navigate("/public/news",{state:item})}>
                  <Col md={8} xs={8} xl={8} style={{paddingLeft:"5%", height:"140px"}}>
                    <img style={item.hasImage?{display:"block", width:"100%", height:"95%"}:{display:"none"}} src={item.image}/>
                  </Col>
                  <Col md={14} xs={14} xl={14} className='truncate' style={{paddingTop:"2%"}}>
                  <div >
                      <h4 className='truncate' style={{fontWeight:"bold", lineHeight:"1"}}>{item.title}</h4>
                      <p className='truncate'>{item.sub}</p>
                  <p style={{textAlign:"right", position:"absolute", float:"right", right:"0", bottom:"0", margin:"0", paddingBottom:"5%"}}>{item.date}</p>
                  </div>                    
                  </Col>
                  <hr style={{background: "#dcdcdc", height:"1px", border:"none", marginLeft:"5%", marginRight:"5%", width:"90%", position:"relative", alignItems:"center"}}></hr>
                </Row>
                </div>
              
            )
          }
        })}
        <p onClick={()=>navigate("/public/news-list")} style={info.length>5?{display:"block", width:"100%", textAlign:"center", fontWeight:"bold", fontSize:"2.5vh", backgroundColor:"#F8F8F8", padding:"3%", cursor:"pointer"}:{display:"none"}}>Цааш үзэх {">>"}</p>
        </Col>
      </Row>

      </div>

    </div>
    </>
  )
}
