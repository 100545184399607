import React from 'react'
import {useLocation, useNavigate, Redirect } from 'react-router-dom';
import './style.css'

export default function News() {
  const { state } = useLocation();
  return (
    <>
    <div className='newsMobile'>
      <h1 style={{width:"100%", textAlign:"center", marginTop:"10%", fontWeight:"800"}}>{state.title}</h1>
      <hr style={{width:"90%", opacity:"0.5"}}></hr>
      <div style={{display:"flex"}}>
        <p style={{textAlign:"left", width:"50%", marginLeft:"10%"}}>{state.author}</p>
        <p style={{textAlign:"right", width:"50%", marginRight:"10%"}}>{state.date}</p>
      </div>
      <img className='newsImageee' style={{marginLeft:"auto", marginRight:"auto", display:"block"}} src={state.image}/>
      <p style={{width:"90%", marginLeft:"auto", marginRight:"auto", textAlign:"justify", marginTop:"10%", marginBottom:"10%"}}>{state.desc}</p>
    </div>
    <div className='newsWeb'>
      <h1 style={{width:"100%", textAlign:"center", marginTop:"5%", fontWeight:"800"}}>{state.title}</h1>
      <hr style={{width:"90%", opacity:"0.5"}}></hr>
      <div style={{display:"flex"}}>
        <p style={{textAlign:"left", width:"50%", marginLeft:"10%"}}>{state.author}</p>
        <p style={{textAlign:"right", width:"50%", marginRight:"10%"}}>{state.date}</p>
      </div>  
      <img className='newsImageee' style={{marginLeft:"auto", marginRight:"auto", display:"block"}} src={state.image}/>
      <p style={{width:"90%", marginLeft:"auto", marginRight:"auto", textAlign:"justify", marginTop:"5%", marginBottom:"10%", fontSize:"2vh"}}>{state.desc}</p>
    </div>
    </>
    

  )
}
