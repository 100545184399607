import axios from "axios";
var url = "https://basketball.spok.devorchin.com/v1/";


export default function getGames(data) {
    var testUrl = 'https://basketball.spok.devorchin.com/v1/competitions/'+data.compId+'/seasons/'+data.seasonId+'/groups/'+data.groupId+'/games?date='+data.date+'&team='+data.team;
    console.log("testUrl", testUrl)
    return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: 'https://basketball.spok.devorchin.com/v1/competitions/'+data.compId+'/seasons/'+data.seasonId+'/groups/'+data.groupId+'/games?date='+data.date+'&team='+data.team,
      // url: 'https://basketball.spok.devorchin.com/v1/competitions/'+data.compId+'/seasons/'+data.seasonId+'/groups/'+data.groupId+'/games?date='+data.date,
      headers: { }
    })
    // .then(function (response) {
    //     ////console.log(JSON.stringify(response.data));
    //   })
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      });
  });
}