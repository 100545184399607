import React, {useEffect} from 'react'
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, message, Space, Tabs, Radio, Table, Divider, Collapse, Select  } from 'antd';
import '../styles.css'
import imageSda from '../../../Images/newsImg.svg'
import locationIcon from '../../../Images/location.svg'
import { useNavigate } from 'react-router-dom';
import player from "../../../Images/SinglePlayer.png"
import './style.css'

const columns = [
    {
      title: 'Баг',
      dataIndex: 'name',
    },
    {
      title: 'W',
      dataIndex: 'Win',
    },
    {
      title: 'L',
      dataIndex: 'Lost',
    },
    {
        title: 'Win %',
        dataIndex: 'WinPercent',
      },
  ];
  const data = [
    {
      key: '1',
      name: 'ШУТИС Шаркс',
      W: 1,
      L: 0,
      WinPercent: 1.00,
    },
    {
        key: '1',
        name: 'ШУТИС Шаркс',
        W: 1,
        L: 0,
        WinPercent: 1.00,
      },
      {
        key: '1',
        name: 'ШУТИС Шаркс',
        W: 1,
        L: 0,
        WinPercent: 1.00,
      },
      {
        key: '1',
        name: 'ШУТИС Шаркс',
        W: 1,
        L: 0,
        WinPercent: 1.00,
      },
  ];

const handleButtonClick = (e) => {
  message.info('Click on left button.');
};
const handleMenuClick = (e) => {
  message.info('Click on menu item.');
};
const items = [
  {
    label: '2021 - 2022',
    key: '1',
  },
  {
    label: '2020 - 2021',
    key: '2',
  },
  {
    label: '2019 - 2020',
    key: '3',
  },
];
const { Panel } = Collapse;
const text = (
    <>
        <div style={{display:"flex", marginBottom:"10%", margin:"0px"}}>
            <div style={{backgroundImage: `url(${player})`, width:"40%", display:"block", marginLeft:"auto", marginRight:"5%"}}>
                <p style={{color:"#fff", textAlign:"center", marginTop:"100%", fontWeight:"600", fontSize:"12px"}}>О. Хамгийнуртнэр</p>
                <div style={{display:"flex", width:"100%", lineHeight:"1"}}>
                    <div style={{width:"50%"}}>
                        <h3 style={{textAlign:"center",fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>12</h3>
                        <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>нас</p>
                    </div>
                    <div style={{width:"50%"}}>
                        <h3 style={{textAlign:"center", fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>180 см</h3>
                        <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>өндөр</p>
                    </div>
                </div>
                <div style={{display:"flex", width:"100%", lineHeight:"1"}}>
                    <div style={{width:"50%"}}>
                        <h3 style={{textAlign:"center",fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>68 кг</h3>
                        <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>жин</p>
                    </div>
                    <div style={{width:"50%"}}>
                        <h3 style={{textAlign:"center", fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>УБ</h3>
                        <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>төрсөн газар</p>
                    </div>
                </div>
            </div>
            <div style={{backgroundImage: `url(${player})`, width:"40%", display:"block", marginLeft:"auto", marginRight:"5%"}}>
                <p style={{color:"#fff", textAlign:"center", marginTop:"100%", fontWeight:"600", fontSize:"12px"}}>О. Хамгийнуртнэр</p>
                <div style={{display:"flex", width:"100%", lineHeight:"1"}}>
                    <div style={{width:"50%"}}>
                        <h3 style={{textAlign:"center",fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>12</h3>
                        <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>нас</p>
                    </div>
                    <div style={{width:"50%"}}>
                        <h3 style={{textAlign:"center", fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>180 см</h3>
                        <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>өндөр</p>
                    </div>
                </div>
                <div style={{display:"flex", width:"100%", lineHeight:"1"}}>
                    <div style={{width:"50%"}}>
                        <h3 style={{textAlign:"center",fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>68 кг</h3>
                        <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>жин</p>
                    </div>
                    <div style={{width:"50%"}}>
                        <h3 style={{textAlign:"center", fontWeight:"600", color:"#fff", margin:"0px", fontSize:"14px"}}>УБ</h3>
                        <p style={{textAlign:"center", color:"#fff", fontSize:"8px"}}>төрсөн газар</p>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
const menuProps = {
  items,
  onClick: handleMenuClick,
};

export default function OtherComp() {
    let navigate = useNavigate();
  return (
    <div style={{ marginTop:"3%"}}>
        <p style={{marginLeft:"5%", color:"#676565"}}>Нүүр {">"} Тэмцээнүүд {">"} U16 улсын аварга</p>
        <div style={{ marginLeft:"5%",display:"flex"}}>
            <p style={{color:"#0A163C", fontWeight:"bold", fontSize:"20px"}}>U16 улсын аварга</p>
            <Space style={{marginLeft:"auto", marginRight:"5%"}} wrap>
                <Dropdown menu={menuProps}>
                <Button style={{borderRadius:"5px"}}>
                    <Space style={{color:"#000", fontWeight:"bold"}}>
                    2022 - 2023
                    <DownOutlined />
                    </Space>
                </Button>
                </Dropdown>
            </Space>
        </div>
        <div style={{backgroundColor:"#112259"}}>
            <Tabs className='otherTab'>
                <Tabs.TabPane tab="Багууд" key="1">
                    <div style={{display:"flex", marginBottom:"3%", backgroundColor:"#737d9d", padding:"5%", borderRadius:"10px", marginRight:"5%", marginLeft:"5%"}}>
                        <Radio.Group style={{display:"flex", width:"100%", justifyContent:"center"}}>
                        <Radio style={{marginRight:"auto", marginLeft:"15%", color:"#fff"}} value={"male"}>Эрэгтэй</Radio>
                        <Radio style={{marginRight:"15%", marginLeft:"auto", color:"#fff"}} value={"female"}>Эмэгтэй</Radio>
                        </Radio.Group>
                    </div>
                    <div style={{marginBottom:"3%", backgroundColor:"#fff", borderRadius:"10px", marginRight:"5%", marginLeft:"5%"}}>
                        <Collapse
                            expandIconPosition={"end"}
                            bordered={false}
                            style={{backgroundColor:"#fff", borderRadius:"10px", position:"relative"}}
                        >
                            <img src={imageSda} style={{borderRadius:"50%", width:"30px", left:"5%", top:"10px", position:"absolute"}}/>
                            <Panel className='sdawe' header="Багийн нэр" key="1" style={{fontWeight:"700"}}>
                                {text}
                            </Panel>
                        </Collapse>
                    </div>
                    <div style={{marginBottom:"3%", backgroundColor:"#fff", borderRadius:"10px", marginRight:"5%", marginLeft:"5%"}}>
                        <Collapse
                            expandIconPosition={"end"}
                            bordered={false}
                            style={{backgroundColor:"#fff", borderRadius:"10px", position:"relative"}}
                        >
                            <img src={imageSda} style={{borderRadius:"50%", width:"30px", left:"5%", top:"10px", position:"absolute"}}/>
                            <Panel className='sdawe' header="Багийн нэр" key="1" style={{fontWeight:"700"}}>
                                {text}
                            </Panel>
                        </Collapse>
                    </div>
                    <div style={{marginBottom:"3%", backgroundColor:"#fff", borderRadius:"10px", marginRight:"5%", marginLeft:"5%"}}>
                        <Collapse
                            expandIconPosition={"end"}
                            bordered={false}
                            style={{backgroundColor:"#fff", borderRadius:"10px", position:"relative"}}
                        >
                            <img src={imageSda} style={{borderRadius:"50%", width:"30px", left:"5%", top:"10px", position:"absolute"}}/>
                            <Panel className='sdawe' header="Багийн нэр" key="1" style={{fontWeight:"700"}}>
                                {text}
                            </Panel>
                        </Collapse>
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Хүснэгт" key="2">
                    <div style={{display:"flex", marginBottom:"3%", backgroundColor:"#737d9d", padding:"5%", borderRadius:"10px", marginRight:"5%", marginLeft:"5%"}}>
                        <Radio.Group style={{display:"flex", width:"100%", justifyContent:"center"}}>
                            <Radio style={{marginRight:"auto", marginLeft:"15%", color:"#fff"}} value={"male"}>Эрэгтэй</Radio>
                            <Radio style={{marginRight:"15%", marginLeft:"auto", color:"#fff"}} value={"female"}>Эмэгтэй</Radio>
                        </Radio.Group>
                    </div>
                    <div style={{ marginBottom:"3%", borderRadius:"10px", marginRight:"5%", marginLeft:"5%"}}>
                        <h3 style={{marginLeft:"10%", color:"#fff", fontWeight:"600"}}>Standing</h3>
                        <Table columns={columns} dataSource={data} size="small" />
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </div>
    </div>
  )
}
