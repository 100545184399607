import axios from "axios";
var url = "https://basketball.spok.devorchin.com/v1/";


export default function getCompetition() {
    //console.log("heheheihihihih",data)
    return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: url + "competitions",
    })
    // .then(function (response) {
    //     ////console.log(JSON.stringify(response.data));
    //   })
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      });
  });
}