import axios from "axios";
var url = "https://basketball.spok.devorchin.com/v1/";


export default function seasonDetail(data) {
    return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: url + "competitions/"+data.compId+"/seasons/"+data.seasonId,
    })
    // .then(function (response) {
    //     ////console.log(JSON.stringify(response.data));
    //   })
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      });
  });
}