import React from 'react'
import { Col, Row } from 'antd';
import './style.css'
import phoneIcon from '../Images/phome-icon.svg'
import locationIcon from '../Images/location-icon.svg'
import mailIcon from '../Images/mail-icon.svg'
import { useNavigate } from 'react-router-dom';


export default function Footer() {
    let navigate = useNavigate();

  return (
    <div style={{backgroundColor:"#151925"}}>    
        <Row gutter={24}>
            <Col xs={24} md={24} lg={24}>
                <p style={{color:"#CC1123", fontWeight:"bold", marginLeft:"5%", marginTop:"5%", fontSize:"20px"}}><i>MBA</i></p>
            </Col>
        </Row>
        <Row gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
        }}>
                        <Col span={24} md={2} lg={1}>
                
            </Col>
            <Col span={8} md={3} lg={3}>
                <p className='footerText'>Бидний тухай</p>
            </Col>
            <Col span={8} md={3} lg={3} onClick={()=>{navigate("/public/tushaal")}}>
                <p className='footerText'>Тушаал, тогтоол</p>
            </Col>
            <Col span={8} md={2} lg={2}>
                <p className='footerText'onClick={()=>{navigate("/public/news-list")}}>Мэдээлэл</p>
            </Col>
        </Row>
        <hr style={{background: "red", height:"1px", border:"none", marginLeft:"5%", marginRight:"5%"}}></hr>
        <p style={{color:"#fff", marginLeft:"5%", fontWeight:"bold", fontSize:"17px"}}>Холбоо барих</p>
        <Row gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
        }}>
            <Col span={24} md={24} lg={24}>
                <div className='footerContactList' style={{display:"flex", alignContent:"center", marginLeft:"5%"}}>
                    <img src={phoneIcon}/>
                    <p className='footerText1'>+976 7777-4144</p>
                </div>

            </Col>
            <Col span={24} md={24} lg={24}>
                <div className='footerContactList' style={{display:"flex", alignContent:"center", marginLeft:"5%"}}>
                    <img src={mailIcon}/>
                    <div className='footerMail'>
                    <p className='footerText1'>secretary@mongolia.basketball</p>
                    <p className='footerText1'>contact@mongolia.basketball</p>
                    </div>

                </div>
            </Col>
            <Col span={24} md={24} lg={24}>
                <div className='footerContactList'  style={{display:"flex", alignContent:"center", marginBottom:"5%", marginLeft:"5%"}}>
                    <img src={locationIcon}/>
                    <p className='footerText1'>Central tower 4 давхар, Сүхбаатар дүүрэг, 8-р хороо, Улаанбаатар, Монгол Улс </p>
                
                </div>
            </Col>
        </Row>
        <p style={{color:"#A8A8A8", textAlign:"center", paddingBottom:"5%"}}>Mongolian Basketball Association © 2023</p>
    </div>

  )
}
