// Import the functions you need from the SDKs you need
import firebase from 'firebase';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA10cis9NbLL0-aHabsjrr_T_42PVUWwaM",
  authDomain: "basketball-486d6.firebaseapp.com",
  projectId: "basketball-486d6",
  storageBucket: "basketball-486d6.appspot.com",
  messagingSenderId: "901023933902",
  appId: "1:901023933902:web:0036fed54c8ddcb80ab79f",
  measurementId: "G-SD681D0L58"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();

// const analytics = getAnalytics(app);
export default db;