import React, { useEffect, useState } from "react";
import {
    format,
    addDays,
    lastDayOfWeek,
    getWeek,
    addWeeks,
    subWeeks,
    subDays
  } from "date-fns";
  import DatePicker from "react-datepicker";
import { Col, Row } from "antd";
import { LeftOutlined, RightOutlined, CalendarOutlined } from '@ant-design/icons';
import moment from "moment";
  

export default function DatePick(props) {
    const [startDate, setStartDate] = useState(new Date());
    const [backCOlor, setBackCOlor] = useState(false);
    const today = new Date();
    const [selectedDates, setSelectedDates] = useState()
  const [adiyaSelect, SetAdiyaSelect] = useState()


    useEffect(()=>{
      var firstString  = props.pickedDate
      setStartDate(new Date(moment(props.pickedDate)))
    
      var temp = handleDate(props.pickedDate)
      console.log("date1", moment(today).format("YYYY-MM-DD"))
      setSelectedDates(temp)
      
    },[props.pickedDate])


    const handleDate = (item) =>{
      var temp= []
      props.handlePickedDate(item)
      var Object  = [1, 2, 3, 4, 5, 6, 7 ]
      for (var i in Object){
        var date =    new Date(moment(item).add(i, "day").format("YYYY-MM-DDTHH:mm"))
        temp.push(date)
      }
      return temp
    }

    const handleLeft = () =>{
      var temp =  handleDate(moment(selectedDates[0]).subtract(7, "day"))
      setSelectedDates(temp)
      }

    const handleRight = () =>{
    var temp =  handleDate(moment(selectedDates[0]).add(7, "day"))
    setSelectedDates(temp)
    }

    const handleDateClick = (date)=>{
      SetAdiyaSelect(date)
      setBackCOlor(true)

      props.handleDatePicker(date)
    }
      return (
        <>
          <div style={{margin:"0% 5% 0% 5%", display:"flex"}}>
                <div onClick={() => handleLeft()}  style={{display:"flex", alignItems:"center"}}>
                  <LeftOutlined />
                </div>
                <div style={{display:"flex", width:"100%"}}>
                  {
                    selectedDates && selectedDates.map((date)=>{
                      var string = ""

                      var number  = moment(date).format("D")
                      switch(moment(date).format("dd")){
                        case "Mo":
                          string = "Да"
                            break;
                        case "Tu":
                          string = "Мя"
                            break;
                        case "We":
                          string = "Лха"
                            break;
                        case "Th":
                          string = "Пү"
                            break;
                        case "Fr":
                          string = "Ба"
                            break;
                        case "Sa":
                          string = "Бя"
                            break;
                        case "Su":
                          string = "Ням"
                            break;
                      }
                      return(
                          <div style={{width:"100%", paddingLeft:"2%", paddingRight:"2%"}}>
                              {
                                moment(adiyaSelect).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD") ? 
                                
                                <div style={{display:"block", cursor:"pointer", width:"100%", marginLeft:"2%", marginRight:"2%", textAlign:"center", color:"#fff",backgroundColor:"#0A163C", borderRadius:"5px", border:"1px #000 solid"}} onClick={()=> handleDateClick(date)}>
                                  <p style={{textAlign:"center", margin:"0"}}>
                                    {string}
                                  </p>
                                  <p style={{textAlign:"center", margin:"0"}}>
                                    {number}
                                  </p>
                                </div>                              
                                      :
                                <div id={moment(date).format("YYYY-MM-DD")} style={moment(date).format("YYYY-MM-DD")===moment(today).format("YYYY-MM-DD")?{display:"block", cursor:"pointer", width:"100%", marginLeft:"2%", marginRight:"2%", textAlign:"center", color:"#fff",backgroundColor:"#d3d3d3", borderRadius:"5px"}:{display:"block", cursor:"pointer", width:"100%", marginLeft:"2%", marginRight:"2%", textAlign:"center", color:"#000",backgroundColor:"#fff", borderRadius:"5px", border:"1px #000 solid"}} onClick={()=> handleDateClick(date)}>
                                  <p style={{textAlign:"center", margin:"0"}}>
                                    {string}
                                  </p>
                                  <p style={{textAlign:"center", margin:"0"}}>
                                    {number}
                                  </p>
                                </div>                              
                              }
                            </div>
                      )
                    })
                  }
                </div>
                <div style={{display:"flex", alignItems:"center"}} onClick={() => handleRight()}>
                  <RightOutlined />
                </div>

          </div>
        </>
      );
    }
    